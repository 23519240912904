(function ($, window, document, undefined) {

  'use strict';

  $(function () {
    function shuffle(a) {
      var j, x, i;
      for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
      }
      return a;
    }

    function redirect(config) {
      const [url, inPlace] = config;

      if (inPlace) {
        location.replace(url);
      } else {
        location.href = url;
      }
    }

    // your code here
    let links = shuffle([
      ["https://archive.teamhns.xyz", false],
      ["https://www.youtube.com/watch?v=dQw4w9WgXcQ", false],
      ["https://www.youtube.com/watch?v=eY52Zsg-KVI", false]
    ]);
    window.links = links;

    const b1 = $("#b1"),
          b2 = $("#b2"),
          b3 = $("#b3");

    b1.click(function() { redirect(links[0]); });
    b2.click(function() { redirect(links[1]); });
    b3.click(function() { redirect(links[2]); });

    console.log("Links set up.");

    // Set up white theme handler
    const theme = $("#theme input"),
          themeText = $("#theme .label"),
          html = $("html");

    theme.change(function() {
      if (this.checked) {
        html.addClass("whitetheme");
        themeText.text("Told ya!");
      } else {
        html.removeClass("whitetheme");
        themeText.text("Are you sure you wanna do that?");
      }
    })

  });

})(jQuery, window, document);
